import React from 'react'
import { graphql } from 'gatsby'

import { ProvideAuth } from '../../components/Auth/useAuth'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import HcpdPageHero from '../../components/HealthCareProviderDirectory/HcpdPageHero'
import RegistrationForm from '../../components/Auth/RegistrationForm'
import ApolloWrapper from '../../components/Auth/ApolloWrapper'

export const query = graphql`
  {
    prismicHcpdNewRegistrationPage {
      data {
        body_text {
          richText
        }
        title {
          text
        }
        display_name
        facebook_image {
          url
        }
        meta_description
        meta_keywords
        meta_title {
          text
        }
        twitter_image {
          url
        }
      }
    }
    prismicHcpdAttestation {
      data {
        disagree_text {
          text
        }
        agree_text {
          text
        }
        attestation_text {
          richText
        }
      }
    }
  }
`

const NewRegistrationPage = ({ data }) => {
  const doc = data.prismicHcpdNewRegistrationPage?.data
  if (!doc) {
    return null
  }
  const attestationData = data.prismicHcpdAttestation?.data
  const twitterImage = doc.twitter_image?.url ? doc.twitter_image : null
  const facebookImage = doc.facebook_image?.url ? doc.facebook_image : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.title?.text
    ? doc.title.text
    : 'New Registration'

  return (
    <ApolloWrapper>
      <ProvideAuth>
        <SEO
          seo={{
            description: doc.meta_description,
            keywords: doc.meta_keywords,
            title: seoTitle,
            twitter_image: twitterImage,
            facebook_image: facebookImage,
          }}
        />
        <Layout>
          <HcpdPageHero
            title={doc.title?.text ?? 'New Registration'}
            body={doc.body_text}
          />
          <RegistrationForm attestationData={attestationData} />
        </Layout>
      </ProvideAuth>
    </ApolloWrapper>
  )
}

export default NewRegistrationPage
